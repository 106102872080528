import { Agent, formatIsbdTitle } from "@biblioteksentralen/cordata";
import { Box, Heading, Image, LinkBox, ListItem, Text, VStack } from "@biblioteksentralen/react";
import { isNonNil } from "@libry-content/common";
import { unique } from "radash";
import { useEffect, useRef, useState } from "react";
import { User } from "react-feather";
import InternalLinkOverlay from "../../../components/InternalLinkOverlay";
import { getPath } from "@libry-content/common";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { useSitePalette } from "../../../utils/useSitePalette";
import { formatAgentName, getAgentTitle } from "../../cordata/agents";
import { getAgentDatesString, getAgentPath } from "../../cordata/contributors";
import { ExternalAgentData } from "../../externalDataFetchers/fetchExternalAgentData";
import { WorkSummary } from "../../hooks/useAgentsData";
import { TruncatedList } from "../TruncatedList";

type AgentPreviewProps = {
  agent: Agent;
  externalData: ExternalAgentData | undefined;
  works: WorkSummary[] | undefined;
  autoFocus?: boolean;
};

const hasName = <T extends { name: string | undefined }>(item: T): item is T & { name: string } => !!item.name;

export const AgentPreview = ({ agent, externalData, works, autoFocus }: AgentPreviewProps) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    autoFocus && ref.current?.focus();
  }, [autoFocus]);

  const { t, ts } = useTranslation();
  const path = getAgentPath(agent);
  const nationalities = unique(agent.nationality?.map(({ demonym }) => demonym)?.filter(isNonNil));
  const datesString = getAgentDatesString(agent, t);
  const name = formatAgentName(ts, agent);
  const title = getAgentTitle(ts, agent);
  const palette = useSitePalette();

  const workItems = works?.map((work) => ({ name: formatIsbdTitle(work), href: getPath(work) })).filter(hasName);

  const [hoverWorkTitle, setHoverWorkTitle] = useState(false);

  const Wrapper = path ? LinkBox : ListItem;

  return (
    <Wrapper
      as={ListItem}
      {...palette.colors.card.css}
      borderRadius="lg"
      justifyContent="flex-start"
      padding="1rem"
      display="flex"
      flexDirection="column"
      alignItems="center"
      height="100%"
      textAlign="center"
      role="group"
    >
      <Box width="6rem" height="6rem" borderRadius="50%" overflow="hidden" marginBottom="1rem">
        {externalData?.wikidataImage?.thumburl && (
          <Image
            src={externalData?.wikidataImage?.thumburl}
            alt={ts(agent.name)}
            width="100%"
            height="100%"
            objectFit="cover"
          />
        )}
        {!externalData?.wikidataImage?.thumburl && (
          <Box
            width="100%"
            height="100%"
            padding="1.5rem"
            {...palette.colors.darkaccent1.css}
            {...palette.colors.darkaccent1.css}
          >
            <User width="100%" height="100%" />
          </Box>
        )}
      </Box>
      <VStack marginBottom="1rem" spacing="0.25rem">
        <Box
          id={`bibbi-agent:${agent.id}`}
          as={path ? InternalLinkOverlay : undefined}
          href={path}
          _groupHover={hoverWorkTitle ? {} : { textDecoration: "underline" }}
          ref={ref}
        >
          <Heading as="h3" fontSize="md" fontWeight="semibold" {...palette.colors.card.css}>
            {name}
          </Heading>
        </Box>
        {!!title && (
          <Text lineHeight="1.2" _firstLetter={{ textTransform: "uppercase" }}>
            {title}
          </Text>
        )}
      </VStack>
      {(datesString || !!nationalities?.length) && (
        <VStack spacing="0" fontSize="sm" {...palette.colors.card.css} lineHeight="1.3" marginBottom="1rem">
          <Text>{datesString}</Text>
          <Text>{nationalities.join(", ")}</Text>
        </VStack>
      )}
      {!!workItems?.length && (
        <TruncatedList
          items={workItems}
          max={3}
          label="verk"
          fontSize="sm"
          lineHeight="1"
          separator=" • "
          seperatorSpacing={true}
          linkProps={{
            textDecoration: "none",
            _hover: { textDecoration: "underline" },
            lineHeight: "1.3",
            style: { hyphens: "none" },
            onMouseEnter: () => {
              setHoverWorkTitle(true);
            },
            onMouseLeave: () => {
              setHoverWorkTitle(false);
            },
          }}
        />
      )}
    </Wrapper>
  );
};
